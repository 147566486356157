<template>
  <b-container class="register-wrapper">
    <b-row>
      <b-col>
        <b-form @submit.prevent="register">
          <b-form-group>
            <b-row>
              <!-- <b-col cols="12">
								<router-link to="/zmudri-do-skol">
									<img src="/zmudriBanner.png" alt="Zmudri do skol" class="w-100 mb-3">
								</router-link>
								<a
									href="https://forms.gle/HUfBuWjxwmhdVPFy7"
									target="_blank" rel="noopener noreferrer" class="w-color-primary font-weight-bold"
								>
									<img src="/zmudriBanner.png" alt="Zmudri do skol" class="w-100 mb-3">
								</a>
								<router-link to="/zmudri-do-skol">
									<img src="/zmudriBanner.png" alt="Zmudri do skol" class="w-100 mb-3">
								</router-link>
								<div class="a-info-section">
									<div class="z-icon-wrapper -large info-icon">
										<svg-award-icon />
									</div>
									<div class="info-text">
										<h4>VYHRAJTE SKVELÉ CENY!</h4>
										<p>
											Milí učitelia, radi by sme vás ocenili za vašu skvelú prácu. Registrujte sa a vyhrajte
											<a href="https://drive.google.com/file/d/1Nhwf3bVwPIYS-cuhFhs4qdHjMSf8YPxg/view?usp=sharing"
												target="_blank" rel="noopener noreferrer" class="w-color-primary font-weight-bold"
											>
												{{ $t('skvelé ceny') }}
											</a>
											pre váš oddych, zdravie, osobný rozvoj alebo vašu triedu.
										</p>
									</div>
								</div>
							</b-col> -->
              <b-col md="6">
                <div
                  class="a-profile-subpage-title -student a-pointer"
                  @click="setProfileType('student')"
                >
                  <label for="radio-student">
                    <h2>{{ $t("Som študent") }}</h2>
                    <p>{{ $t("Ktokoľvek, kto sa rád vzdeláva :)") }}</p>
                  </label>
                  <b-form-radio
                    v-model="user.type"
                    value="student"
                    id="radio-student"
                    class="a-checkbox-circle"
                    name="registrationType"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div
                  class="a-profile-subpage-title -teacher a-pointer"
                  @click="setProfileType('teacher')"
                >
                  <label for="radio-teacher">
                    <h2>{{ $t("Som učiteľ") }}</h2>
                    <p>{{ $t("Akýkoľvek učiteľ/ka z ktorejkoľvek školy") }}</p>
                  </label>
                  <b-form-radio
                    v-model="user.type"
                    value="teacher"
                    id="radio-teacher"
                    class="a-checkbox-circle"
                    name="registrationType"
                  />
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <div ref="formsWrapper" v-if="user.type" class="forms-wrapper">
            <sup dev @click.prevent="dev_prefill()">prefill</sup>

            <UserInfo :user="user" />
            <UserPasswordChange :user="user" showRepeat="true" />
            <RegisterForm :profile="user" />

            <b-row align-v="center">
              <b-col cols="12">
                <b-form-group>
                  <template v-slot:label>
                    {{
                      isTeacher
                        ? $t("Odkiaľ ste sa o nás dozvedeli?")
                        : $t("Odkiaľ si sa o nás dozvedel/a?") + " *"
                    }}
                    <br />
                    <span class="font-italic">{{
                      isTeacher
                        ? $t("Môžete vybrať viacero možností")
                        : $t("Môžeš vybrať viacero možností")
                    }}</span>
                  </template>
                  <b-form-checkbox-group
                    v-model="user.heardFrom"
                    :options="
                      isTeacher
                        ? heardFromTeacherOptions
                        : heardFromStudentOptions
                    "
                    value-field="slug"
                    text-field="name"
                    class="heard-from mt-2"
                  />
                </b-form-group>

                <StyledInput
                  v-if="containsHeardFromOptionOther"
                  v-model="user.heardFromOther"
                  type="text"
                  :label="
                    (isTeacher
                      ? $t('Z akých iných zdrojov ste sa o nás dozvedeli?')
                      : $t('Z akých iných zdrojov si sa o nás dozvedel/a?')) +
                    ' *'
                  "
                  :placeholder="$t('Vymenujte zdroje')"
                />
                <StyledInput
                  v-if="containsHeardFromOptionOtherOrgs"
                  v-model="user.heardFromOtherOrg"
                  type="text"
                  :label="
                    (isTeacher
                      ? $t('register-other-org-teacher')
                      : $t('register-other-org-student')) + ' *'
                  "
                  :placeholder="$t('list-orgs')"
                />
              </b-col>
            </b-row>

            <b-row v-if="isTeacher">
              <b-col>
                <b-form-group :label="$t('Chcete nám niečo odkázať?')">
                  <b-form-textarea
                    v-model="user.comment"
                    rows="3"
                    :placeholder="$t('Napíšte sem svoj odkaz pre nás...')"
                    class="mt-2"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-v="center">
              <b-col cols="12">
                <div
                  class="form-group d-flex align-items-center is-gdpr-accepted-wrapper pt-2 pb-2 pr-3"
                >
                  <b-form-checkbox
                    v-model="user.gdprAccepted"
                    id="isGdprAccepted"
                    class="a-checkbox-circle"
                  />
                  <label for="isGdprAccepted">
                    {{ $t("Súhlasím s") }}
                    <a
                      style="color: blue"
                      href="https://docs.google.com/document/d/1MfD458KtyvPbU9ifGu0lnSAFUsOcNr4z/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ $t("podmienkami spracovánia osobných údajov") }}
                    </a>
                  </label>
                </div>
              </b-col>
            </b-row>

            <!-- <b-row align-v="center" v-if="isTeacher">
							<b-col cols="12">
								<p>
									Registráciou súhlasite s
									<a
										style="color: blue;"
										href="https://docs.google.com/document/d/1CMVADNcVp-TTe07CQQFaJOSHbdQdBiB3/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true"
										target="_blank" rel="noopener noreferrer"
									>
										Podmienkami súťaže
									</a>
									a
									<a
										style="color: blue;"
										href="https://docs.google.com/document/d/1I5rWkGV-zu16tIS_ZQOEESd9Vpftfgrf/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true"
										target="_blank" rel="noopener noreferrer"
									>
										Spracovaním OÚ pre účely súťaže
									</a>
								</p>
							</b-col>
						</b-row> -->

            <b-button type="submit">{{ $t("Registrovať") }}</b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue";
import axios from "/utils/axios";
import { normalizeEnumEntityResponse } from "/utils/helpers";
import { isEmpty } from "lodash";
import * as Sentry from "@sentry/vue";

const api = {
  heardFrom: () =>
    axios.get("heard-from-options", {
      params: {
        populate: ["info"],
        sort: ["sortOrder"],
      },
    }),
};

export default {
  components: {
    UserInfo: () => import("/components/UserInfo"),
    UserPasswordChange: () => import("/components/UserPasswordChange"),
    RegisterForm: () => import("/components/RegisterForm.vue"),
    StyledInput: () => import("/components/StyledInput.vue"),
  },

  data() {
    return {
      user: {
        _lang: this.$root.$i18n.locale,
        type: null,
        attendingSchool: false,
        school: null,
        schoolConnect: {
          id: null,
          cityConnect: {
            id: null,
          },
        },
        schoolCreate: {
          name: "",
          address: "",
          cityCreate: {
            name: "",
          },
        },
        subjects: [],
        subjectsOther: "",
        phone: "",
        comment: "",
        state: null,
        acceptedMarketingTerms: true, // TODO: dorobit marketing accept
        heardFrom: [],
        heardFromOther: null,
        heardFromOtherOrg: null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        password_confirmation: "",
        gdprAccepted: false,
      },
      dataChanges: 0,
      heardFromOptions: [],
    };
  },

  computed: {
    isTeacher() {
      return this.user.type === "teacher";
    },
    containsHeardFromOptionOther() {
      return Boolean(
        this.user.heardFrom.find(
          (heardFromOption) =>
            heardFromOption.startsWith("odkial-sa-dozvedeli") &&
            heardFromOption.endsWith("-ine")
        )
      );
    },
    containsHeardFromOptionOtherOrgs() {
      return Boolean(
        this.user.heardFrom.find(
          (heardFromOption) =>
            heardFromOption.startsWith("odkial-sa-dozvedeli") &&
            heardFromOption.endsWith("-ine-organizacie")
        )
      );
    },
  },

  watch: {
    "$root.$i18n.locale": {
      handler: function (locale) {
        this.user._lang = locale;
      },
    },
    user: {
      deep: true,
      handler() {
        this.dataChanges++;
      },
    },
    "user.type": {
      flush: "post",
      handler() {
        if (window.innerWidth < 768)
          Vue.nextTick(() =>
            this.$refs.formsWrapper.scrollIntoView({ behavior: "smooth" })
          );
      },
    },
  },

  async created() {
    const isSafari = () =>
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    window.addEventListener(
      "beforeunload",
      (event) => {
        if (this.dataChanges >= 2 && !isSafari()) {
          event.preventDefault();
          return (event.returnValue = "");
        }
      },
      { capture: true }
    );
    try {
      const response = await api.heardFrom();

      this.heardFromOptions = normalizeEnumEntityResponse(response);

      this.heardFromTeacherOptions = this.heardFromOptions.filter(
        (option) => option.type === "teacher"
      );

      this.heardFromStudentOptions = this.heardFromOptions.filter(
        (option) => option.type === "student"
      );
    } catch (error) {
      Sentry.captureException(error);
      this.$toast.error(error);
    }
  },

  methods: {
    async register() {
      if (!this.user.email) {
        this.$toast.error(this.$t("The email field is required."));
        return;
      }

      if (!this.user.firstName) {
        this.$toast.error(this.$t("The name field is required."));
        return;
      }

      if (!this.user.lastName) {
        this.$toast.error(this.$t("The surname field is required."));
        return;
      }

      if (isEmpty(this.user.heardFrom)) {
        this.$toast.error(this.$t("The heard from field is required."));
        return;
      }

      if (!this.user.password || !this.user.password_confirmation) {
        this.$toast.error(this.$t("The password field is required."));
        return;
      }

      if (this.user.password !== this.user.password_confirmation) {
        this.$toast.error(this.$t("The password confirmation does not match."));
        return;
      }

      if (!this.user.gdprAccepted) {
        this.$toast.error(this.$t("The is gdpr accepted must be accepted."));
        return;
      }

      try {
        this.dataChanges = 0; // enables to navigate without popup
        await this.$store.dispatch("auth/signup", this.user);

        this._flagTeacherEncouragementModal();
        this._sendRegistrationEventToGtm();

        const lastPath = localStorage.getItem("LAST_FROM_ROUTE");

        // only redirect back to the page if it was a course
        // otherwise redirect to the quickstart pages
        await this.$router.push(
          lastPath &&
            (lastPath.includes("/kurz/") || lastPath.includes("/seria/"))
            ? lastPath
            : this.isTeacher
            ? "/news"
            : "/oblubene-kurzy"
        );

        this.$toast.success(
          this.$t("Registration was succesful! Welcome to the platform!")
        );
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },

    _flagTeacherEncouragementModal() {
      if (this.isTeacher) {
        localStorage.setItem("a-create-group-encouragement-modal", "false");
      }
    },

    _sendRegistrationEventToGtm() {
      if (this.$gtm && this.$gtm.enabled() && window.dataLayer) {
        const event = this.isTeacher
          ? "registration_teacher"
          : "registration_student";
        window.dataLayer.push({ event });
      }
    },

    setProfileType(type) {
      this.user.type = type;
      this.user.heardFrom = [];
      this.user.heardFromOther = null;
      this.user.heardFromOtherOrg = null;
    },

    dev_prefill() {
      const time = new Date().getTime();

      this.user.firstName = `Testing-${time}`;
      this.user.lastName = "Wezeo";
      this.user.email = `testing+${time}@example.com`;
      this.user.password = "12345678";
      this.user.password_confirmation = "12345678";
      this.user.gdprAccepted = false;
      this.user.attendingSchool = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dataChanges < 2) {
      next();
      return;
    }

    const answer = window.confirm(this.$t("unsaved-changes"));

    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
</script>

<!-- Native html form validation does not work with smooth scroll -->
<style lang="scss">
html {
  scroll-behavior: auto;
}
</style>

<style lang="scss" scoped>
.register-wrapper {
  padding-bottom: 150px;

  @media screen and (min-width: 1200px) {
    padding: 0 160px 300px 160px;
  }

  .a-info-section {
    .info-icon {
      width: 80px;
      margin-right: 30px;
    }

    .info-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      * {
        color: white;
      }

      p {
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 0px;

        @media only screen and (max-width: 1400px) {
          margin-top: 0px;
          font-size: 14px;
        }
      }
    }
  }

  .forms-wrapper {
    padding: 0 250px;

    @media screen and (max-width: 1000px) {
      padding: 0 0px;
    }
  }
}

.heard-from {
  padding: 1.675rem 2.5rem 0.75rem;
  border-radius: 0.675rem;
  display: flex;
  flex-wrap: wrap;

  &::v-deep .custom-control-inline {
    display: block;
    margin-right: 0;
    margin-bottom: 0.5rem;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.is-gdpr-accepted-wrapper {
  height: 87px;
  border-radius: 10px;
  padding-left: var(--a-form-group-padding-left);
}

// @media (min-width: 768px) {
// 	.heard-from {
// 		/deep/ .custom-control-inline {
// 			flex: 0 0 50%;
// 			max-width: 50%;
// 		}
// 	}
// }

// @media (min-width: 992px) {
// 	.heard-from {
// 		/deep/ .custom-control-inline {
// 			flex: 0 0 33%;
// 			max-width: 33%;
// 		}
// 	}
// }
</style>
